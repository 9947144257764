/**
 * Custom hooks are stateful, reusable chunks of logic that we can use in functional components
 * Handy to cut down on repetitive boilerplate
 */
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom';

/**
 * This hook handles pagination state, for when we don't want to use url search params (e.g. for infinite scroll?)
 * @param {object} initialPagination - a pagination object, default is { page: 1, per: 10 }
 * @returns the pagination object and `setPage` and `setPer` functions
 */
export const usePagination = (initialPagination = {}) => {

  // use the built-in `useState` hook to handle state
  const [pagination, setPagination] = useState(initialPagination);

  // create specific actions to update pagination
  const setPage = newPage => {
    setPagination(state => ({ ...state, page: newPage || 1 }));
  }

  const setPer = newPer => {
    // reset the page to 1 when we change the per because the number of pages will change (and could be smaller than the current page)
    setPagination(state => ({ page: 1, per: newPer || 10 }));
  }

  return { ...pagination, setPage, setPer };
}

/**
 *
 * @returns {boolean} true if the window is focused, false otherwise
 */
export const useIsFocused = () => {
  const [isFocused, setIsFocused] = useState(document.visibilityState === 'visible');
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return isFocused;
}

/**
 *
 * @param {object} defaultValues - an object of default values to assign to the url search params (if they aren't already there)
 * @returns {[queryObject: object, setURLParams: Function]} an array with an object containing the search params as key/value pairs and a function to update the search params object
 */
export const useURLSearchParams = (defaultValues = {}) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // on mount, set default values
  useEffect(() => {
    Object.keys(defaultValues).forEach(key => {
      if(!searchParams.has(key)) {
        searchParams.set(key, defaultValues[key].toString());
      }
    });
    history.replace({ search: searchParams.toString() });
  }, []);

  // return an object of key value pairs matching the search params to be used in list query
  const queryObject = useMemo(() => {
    const obj = {};
    searchParams?.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }, [searchParams]);

  // accepts an object of key/value pairs to update OR a single key/value pair as before, useful for updating multiple params at once
  const setURLParams = useCallback((...args) => {
    if(args.length === 2) {
      const [key, value] = args;
      if(searchParams?.get(key) !== value) {
        searchParams?.set(key, value);
      }
      history.push({ search: searchParams?.toString() });
    } else if(args.length === 1 && typeof args[0] === 'object') {
      const [obj] = args;
      Object.keys(obj).forEach(key => {
        if(searchParams?.get(key) !== obj[key]) {
          searchParams?.set(key, obj[key]);
        }
      });
      history.push({ search: searchParams?.toString() });
    } else {
      throw new Error('useURLSearchParams: `handleChange` must be called with either a key/value pair or an object of key/value pairs, received: ' + args + '.');
    }
  }, [searchParams, history]);


  return [queryObject, setURLParams];
}

/**
 *
 * @param {object} options - an object with the following properties:
 * - suggestionMap: an object of variables to be used in the suggestions list
 * - value: the current value of the input
 * - change: a function to be called when the input value changes
 * - delimiter: an object with `open` and `close` properties that define the delimiters for variables in the input
 * @returns
 * - suggestions: an array of suggestions to be displayed in the suggestions list
 * - selectedSuggestion: the index of the currently selected suggestion in the suggestions list
 * - suggestionsVisible: a boolean indicating whether the suggestions list should be displayed
 * - handleSuggestionClick: a function to be called when a suggestion is clicked
 * - inputProps: an object containing props to be spread on the input element
 *
 */
export const useSuggestions = ({
  suggestionMap = {}
  , value = ''
  , inputRef
  , change = () => { }
  , delimiter = { open: '[', close: ']' }
}) => {

  useEffect(() => {
    // hide suggestions list when there are no suggestions
    if(Object.keys(suggestionMap).length === 0) {
      setSuggestionsVisible(false);
    }
  }, [suggestionMap]);

  const setNewValue = (newValue) => {
    change(newValue);
  }

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [selectedVariableIndex, setSelectedVariableIndex] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

  // flattens the variables map into a new object where each key is a path to a variable.
  // e.g. { user: { id: 1, name: 'John' } } => { 'user.id': 1, 'user.name': 'John' }
  // we use these keys to build the suggestions list and to replace the variable in the input value
  const flattenSuggestions = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const pre = prefix.length ? prefix + '.' : '';
      if(typeof obj[key] === 'object') {
        acc = { ...acc, ...flattenSuggestions(obj[key], pre + key) }; // Recursive call for nested properties
      } else {
        acc[pre + key] = obj[key]; // Base case: property is not nested
      }
      return acc;
    }, {});
  };

  // setup the flattened variables object
  const flatSuggestions = flattenSuggestions(suggestionMap);

  // set default suggestions if there are no suggestions and we have a flattened variables object
  useEffect(() => {
    if(!value && suggestions.length === 0 && Object.keys(flatSuggestions).length > 0) {
      setSuggestions(Object.keys(flatSuggestions));
    }
  }, [flatSuggestions]);
  // called when the input value changes. It updates the value state and suggestions.
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setNewValue(newValue);
    setSelectedSuggestionIndex(null);  // reset selected suggestion
    updateSuggestions(newValue, e.target.selectionStart);
  };


  // checks if the cursor is inside a variable, highlights it, and shows suggestions for replacement.
  const handleInputClick = (e) => {
    if(!value) return;
    const cursorPosition = e.target.selectionStart;
    if(cursorPosition === 0) return;
    if(cursorPosition === value.length) return;
    const prevOpenCharPosition = value.lastIndexOf(delimiter.open, cursorPosition);
    const prevCloseCharPosition = value.lastIndexOf(delimiter.close, cursorPosition);
    const nextOpenCharPosition = value.indexOf(delimiter.open, cursorPosition);
    const nextCloseCharPosition = value.indexOf(delimiter.close, cursorPosition);
    const insideEmptyVariable = prevOpenCharPosition === cursorPosition - 1;
    const clickedOutsideVariable = !insideEmptyVariable && prevOpenCharPosition <= prevCloseCharPosition && nextOpenCharPosition <= nextCloseCharPosition;
    if(clickedOutsideVariable) {
      // if the cursor is outside a variable, reset the selected variable and hide suggestions
      setSelectedVariableIndex(null);
      setSelectedSuggestionIndex(null);
      setSuggestionsVisible(false);
      return;
    }
    // if the cursor is inside a variable, highlight it and show suggestions
    // grab the sting with the delimiters
    const selectedVariable = value.substring(value.lastIndexOf(delimiter.open, cursorPosition), value.indexOf(delimiter.close, cursorPosition) + 1);
    setSelectedVariableIndex(value.lastIndexOf(selectedVariable, cursorPosition));
    // grab what's inside the delimiters
    const selectedSuggestionString = selectedVariable.substring(1, selectedVariable.length - 1);
    setSelectedSuggestionIndex(suggestions.indexOf(selectedSuggestionString));
    setSuggestions(Object.keys(flatSuggestions));
    setSuggestionsVisible(true);
    const variablePosition = value.lastIndexOf(selectedVariable, cursorPosition);
    // select everything including the delimiters
    e.target.setSelectionRange(variablePosition, variablePosition + selectedVariable.length);
  };


  // handles arrow keys and enter/tab for keyboard navigation of suggestions list (when it's open)
  const handleKeyDown = (e) => {
    if(e.key.toString() === delimiter.open) {
      setSelectedVariableIndex(e.target.selectionStart);
      setSuggestionsVisible(true)
    } else if(e.key.toString() === delimiter.close) {
      setSuggestionsVisible(false)
    } else if(e.key === 'ArrowDown') {
      if(suggestionsVisible) {
        e.preventDefault();
        setSelectedSuggestionIndex(prev => (prev + 1) % suggestions.length);
      }
    } else if(e.key === 'ArrowUp') {
      if(suggestionsVisible) {
        e.preventDefault();
        setSelectedSuggestionIndex(prev => (prev - 1 + suggestions.length) % suggestions.length);
      }
    } else if(e.key === 'Escape') {
      e.preventDefault();
      setSuggestionsVisible(false);
    } else if(e.key === 'Backspace' && value[value.length - 1] === delimiter.open) {
      setSelectedVariableIndex(null);
      setSuggestionsVisible(false);
    } else if(e.key === 'Enter' || e.key === 'Tab') {
      if(suggestionsVisible) {
        e.preventDefault();
        // if we don't have a selected suggestion, use the first one
        handleSuggestionClick(suggestions[selectedSuggestionIndex ?? 0]);
      }
    }
  };


  // called when a suggestion is clicked. It replaces the selected variable in the input with the clicked suggestion.
  const handleSuggestionClick = (suggestion) => {
    if(selectedVariableIndex !== null) {
      const prefix = value.substring(0, selectedVariableIndex);
      const nextClose = value.indexOf(delimiter.close, selectedVariableIndex) === -1 ? value.length : value.indexOf(delimiter.close, selectedVariableIndex) + 1;
      const nextOpen = value.indexOf(delimiter.open, selectedVariableIndex + 1) === -1 ? value.length : value.indexOf(delimiter.open, selectedVariableIndex + 1);
      // these are used to determine the suffix of the string to be inserted after the variable
      const defaultDelimiters = [' ', ')'] // next space or next close paren, possibly others TBD
      const nextDefaultDelimiter = defaultDelimiters.reduce((acc, cur) => {
        const next = value.indexOf(cur, selectedVariableIndex) === -1 ? value.length : value.indexOf(cur, selectedVariableIndex);
        return next < acc ? next : acc;
      }, value.length);
      // get the index of the next delimiter after the selected variable
      const suffixStart = Math.min(nextClose, nextOpen, nextDefaultDelimiter, value.length);
      const suffix = value.substring(suffixStart);
      const newValue = prefix + delimiter.open + suggestion + delimiter.close + suffix;
      setNewValue(newValue);
      setSelectedVariableIndex(null);
    } else {
      const prefix = value.substring(0, Math.max(value.lastIndexOf(delimiter.open), value.length));
      const newValue = prefix + delimiter.open + suggestion + delimiter.close;
      setNewValue(newValue);
    }
    inputRef.current.focus();
    setSelectedSuggestionIndex(null);
    setSuggestionsVisible(false);
  };

  // updates the suggestions based on the current input value.
  const updateSuggestions = (value, cursorPosition) => {
    const lastChar = value[cursorPosition - 1];
    if(lastChar === delimiter.open) {
      setSuggestions(Object.keys(flatSuggestions));
      setSuggestionsVisible(true);
    } else if(lastChar === delimiter.close) {
      setSuggestionsVisible(false);
    } else {
      const term = value.substring(value.lastIndexOf(delimiter.open, cursorPosition) + 1, cursorPosition);
      setSelectedVariableIndex(value.lastIndexOf(delimiter.open, cursorPosition));
      setSuggestions(Object.keys(flatSuggestions).filter(variable => variable.toLowerCase().includes(term.toLowerCase())));
    }
  };

  return {
    suggestions
    , selectedSuggestion: selectedSuggestionIndex
    , suggestionsVisible
    , handleSuggestionClick
    , inputProps: {
      change: handleInputChange
      , onClick: handleInputClick
      , onKeyDown: handleKeyDown
    }
    , hideSuggestions: () => setSuggestionsVisible(false)
  };
};
