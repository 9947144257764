// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// import form components
import {EmailInput, PasswordInput, SelectInput, TextInput} from '../../../global/components/forms'

const UserRegisterForm = ({
  handleChange,
  handleSubmit,
  user,
  redirectPath,
  errors,
  organisations
}) => {
  const location = useLocation();

  return (
    <div className="border border-solid bg-white shadow-sm rounded-sm mx-auto max-w-lg p-4 mt-16">
      <form name="userForm" onSubmit={handleSubmit}>
        <h1 className="pb-4"> Register </h1>
        <p className="pb-2">Organisation <span className="text-red-800">*</span></p>
        <SelectInput
            multiple={false}
            change={handleChange}
            items={organisations}
            displayKey={'name'}
            valueKey={'_id'}
            selectedValue={user.organisation}
            name="organisation"
            error={errors.organisation_error}
        />
        <p className="pb-2">First name <span className="text-red-800">*</span></p>
        <TextInput
            border="border rounded border-stone-200"
            change={handleChange}
            name="first_name"
            value={user.first_name}
            error={errors.first_name_error}
        />
        <p className="pb-2">Last name <span className="text-red-800">*</span></p>
        <TextInput
            border="border rounded border-stone-200"
            change={handleChange}
            name="last_name"
            value={user.last_name}
            error={errors.last_name_error}
        />
        <p className="pb-2">Email Address <span className="text-red-800">*</span></p>
        <EmailInput
            handleError={errors.email_error}
            name="username"
            value={user.username}
            change={handleChange}
            required={true}
        />
        <p className="pb-2">Password <span className="text-red-800">*</span></p>
        <PasswordInput
            errorHandle={errors.password_error}
            name="password"
            value={user.password}
            change={handleChange}
            required={true}
        />
        <div className="py-2">
          <button className="btn-success w-full" type="submit">Register</button>
        </div>
      </form>
      <div className="flex">
        <p className="py-2 text-sm">Already have an account? </p>
        {redirectPath ?
            <Link
              to={`/user/login/${redirectPath}`}
              className="mx-2 text-blue-700 text-sm my-auto"
            >
              Sign in
            </Link>
        :
          <Link
            to={{
              pathname: "/user/login"
              , state: location.state
            }}
            className="mx-2 text-blue-700 text-sm my-auto"
          >
            Sign in
          </Link>
        }

      </div>
    </div>
  )
}

UserRegisterForm.propTypes = {
  handleChange: PropTypes.func.isRequired
  , handleSubmit: PropTypes.func.isRequired
  , user: PropTypes.object.isRequired
  , errors: PropTypes.object.isRequired
}

export default UserRegisterForm;
